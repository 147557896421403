import React from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';

import classes from '../../assets/css/components/LocationInput/LocationInput.module.css'

const LocationInput = props => (
    <Form noValidate onSubmit={props.submitHandler} validated={props.validated} className={classes.formCustom}>
        <FormControl required className={classes.inputCustom} type="number" max="99999" placeholder="Zip" id="locationInput" value={props.location} onChange={props.locationChangedHandler} />
        <Button className={classes.buttonCustom} type="submit" required>Submit</Button>
    </Form>
);
   
export default LocationInput;