import React, { useState, useEffect, useCallback } from "react";
import axios from "../../axios";
import moment from "moment";
import Jumbotron from "react-bootstrap/Jumbotron";
import _ from "underscore";

import DaySummary from "../../components/DaySummary/DaySummary";
import LocationInput from "../../components/LocationInput/LocationInput";

import classes from "../../assets/css/containers/FiveDayForecast/FiveDayForecast.module.css";

const Week = props => {
  const [forecastData, setForecastData] = useState([]);
  const [locationData, setLocationData] = useState({});
  const [location, setLocation] = useState('');
  const [locationFormValidated, setlocationFormValidated] = useState(false);

  const localDate = date => new Date(date * 1000);

  const getForecast = useCallback(async locationZip => {
    let newForecastData = [];
    const results = await axios.get(
      `/forecast/?units=imperial&zip=${locationZip},us&appid=${process.env.REACT_APP_WEATHER_API}`
    );
    const grouped = _.groupBy(results.data.list, item =>
      item.dt_txt.substring(0, 10)
    );
    for (const key of Object.keys(grouped)) {
      let high = 0;
      let low = 0;

      for (const [i, x] of grouped[key].entries()) {
        if (i === 0) low = x.main.temp_min;
        if (x.main.temp_min < low) {
          low = x.main.temp_min;
        }
      }

      for (const y of grouped[key]) {
        if (y.main.temp_max > high) {
          high = y.main.temp_max;
        }
      }

      const record = grouped[key].filter(item =>
        item.dt_txt.includes("21:00:00")
      );

      if (record.length) {
        record[0].highTemp = Math.floor(high);
        record[0].lowTemp = Math.round(low);
        newForecastData.push(record[0]);
      }
    }

    setForecastData(newForecastData);
    setLocationData(results.data.city);
  }, [])

  useEffect(() => {
    getForecast('91607')
    setLocation('91607')
  }, [getForecast])

  const locationInputHandler = event => {
    setLocation(event.target.value)
  };

  const submitHandler = async event => {
    event.preventDefault();
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.stopPropagation();
    }
    if (location.length === 5) {
      setlocationFormValidated(true);
      await getForecast(location);
    }
  };

  const weather = forecastData.map(item => {
    return (
      <DaySummary
        key={item.dt_txt}
        title={moment(localDate(item.dt)).format("dddd")}
        subtitle={moment(localDate(item.dt)).format("M/DD")}
        hiTemp={item.highTemp}
        lowTemp={item.lowTemp}
        condition={item.weather[0].main}
        iconCode={item.weather[0].id}
      />
    );
  });

  return (
    <>
      <Jumbotron className={classes.jumboCustom}>
        <h4>Five Day Forecast {locationData.name && `For ${locationData.name}`}</h4>
        <LocationInput
          locationChangedHandler={locationInputHandler}
          location={location}
          submitHandler={event => submitHandler(event)}
          validated={locationFormValidated}
        />
      </Jumbotron>
      <div className={classes.weatherContainer}>{weather}</div>
    </>
  );
};

export default Week;
