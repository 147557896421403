import React from 'react';

import FiveDayForecast from './containers/FiveDayForecast/FiveDayForecast';

const App = props => {
  return (
    <div style={{maxWidth: '800px', width: '95%'}} className="ml-auto mr-auto d-flex justify-content-between flex-column">
      <FiveDayForecast />
    </div>
  );
}

export default App;
