import React from 'react';
import Card from 'react-bootstrap/Card';

import classes from '../../assets/css/components/DaySummary/DaySummary.module.css';

const DaySummary = props => (
    <div key={Math.random()} className={classes.cardCustom}>
        <Card.Body className={classes.cardInfoContainer}>
            <Card.Title className={classes.cardInfoItem}>{props.title} <br /><small className="font-weight-light">{props.subtitle}</small></Card.Title>
            <div className={`${classes.iconContainer} ${classes.cardInfoItem}`}>
                <i className={`owf owf-${props.iconCode} owf-5x`}></i>
            </div>
            <div className={`${classes.cardInfoItem} ${classes.infoContainer}`}>
                <p>{props.condition}</p>
                <p>
                    <span>{props.hiTemp}&deg; / {props.lowTemp}&deg;</span>
                </p>
            </div>
        </Card.Body>
    </div>
);
   
export default DaySummary;